.smallCookieBanner
{
    position: fixed;
    bottom: 5px;
    background-color: #a89d8a;
    padding: 8px;
    margin: 0px 16px 0px 16px;
    bottom: 5px;
    color: white;
    border-radius: 8px;
}

.cookieBanner
{
    background-color: #a89d8a;
    padding: 8px;
    margin: 0px 16px 0px 16px;
    bottom: 5px;
    max-width: 500px;
    color: white;
    border-radius: 8px;
    font-size: 12px;
    position: fixed;


    a
    {
        color: black;
        font-weight: bold;
    }


}

.allCookies
{
    background-color: blue;
}


