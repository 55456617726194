.container
{
    border-style:solid;
    border-width:1px;
    border-radius:5px;
    padding:15px;
}

.privacyPolicy
{
    font-size: small;
    color: gray;
    padding-top:3px;
}
