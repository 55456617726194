@import '../styles/definitions.scss';


.wholePage
{
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
}

/*
.mainContent
{
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    width: 100%;
    max-width: $mainContentWidth;
}*/

.mainContent
{
    display: block;
    width: 100%;
    max-width: $mainContentWidth
}

.extraWide
{
    max-width: 100%;
}



.mainContainer
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0px 10px 0px 10px;
}
