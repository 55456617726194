.list
{
    display: flex;
    flex-direction: row;
}

.listItem
{
    padding-left:2px;
    margin-left:2px;
}