.banner
{
    width:100%;
   
    background-color: #d4a748;
    color:white;    
    padding:8px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.banner a
{ 
    font-weight: bold;
    color: white;
    text-decoration: none;

}

.bannerContent
{
    text-align: center;
}
