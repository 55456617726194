.topLine
{
    text-align: center;
    margin-left:20px;
    margin-right:20px;
}

.carousel
{
    max-height: 260px;
    //aspect-ratio: 104/26;  ///doesn't work on Safari
    width:100%;
    margin:5px;
    border-radius: 5px;
    overflow: hidden;
   // border-style: solid;
    max-width:1040px;
}

.mainContent
{
    display:flex;
    flex-direction: column;
    align-items: center;
}

/*
.pane:hover
{
    //opacity: 1;
}
*/
.paneText
{

    position: absolute;
    display: flex;
    align-items: center;
    bottom:0px;
    left:0px;
    right:0px;
    top:0px;
    justify-content: center;
    h2 {
        border-style:solid;
        border-width: 4px;
        padding:20px;
        border-color:white;
        background-color: rgba(255,255,255,0.3);
        border-radius: 10px;
        font-size: 48px;

        @media only screen and (max-width:970px)
        {
            font-size: 36px;
        }
        font-style: normal;

        a 
        { 
            color: rgba(255,255,255,1);
            text-decoration: none;          
        }        
    
    }

    h2:hover
    {
        background-color: rgba(255,255,255,0.4);
    }

}

.pane
{
    //opacity: 0.8;
    background-color: lightgray;
    margin: 16px 16px 16px 16px;
    border-radius: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 500px;
    min-width: 300px;
    flex-basis: 400px;
}

.paneCollection
{
    display: flex;
    justify-content: center;
    flex-flow: wrap;
}

.paneImage
{
    position: relative;
    img{
        border-radius: 10px 10px 0 0;
    }
    flex-shrink: 1;
    display:flex;
    min-width: 300px;
    min-height: (300 * 9 / 16)px;
    //aspect-ratio: 16/9;  DOESN'T WORK ON SAFARI
    max-width:500px;
    max-height: (500 * 9 / 16)px;
    height:100%;
    
}

.imageWrapper
{
    position: absolute;
    width: 100%;
    height: 100%;
}
.paneBlock
{
    padding: 10px;
}


