$navColor: #826644;
$maxHeaderWidth: 1165px;
$mainContentWidth: 1165px;
$maxFooterWidth: 1165px;
$footerColor: #826644;

//DON'T FORGET TO CHANGE THIS IN HEADER.TSX TOO
$headerBreakpoint:925px;

$smBreakpoint: 768px;

