@import '../styles/definitions.scss';

.mainContent
{
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 100%;
    max-width: $maxFooterWidth;
    color:white;   
    padding-left:10px;
    padding-right:10px;
}

.divider
{
    height: 15px;
    max-width: 600px;
    min-width: 200px;
    border-width:2px 0px 0px 0px;
    color:black;
    border-style: solid;
    align-self: center;
}

.innerContent
{
    background-color: $footerColor;
    width: 100%;
    padding:25px;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    /*
    @media only screen and (min-width: $smBreakpoint) {
    div:last-child {
        margin-left: auto;
      }
    }*/

}

.extraWide
{
    max-width: 100%;
}

.mainContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
}

.footerSection
{
    
    margin-right: 18px;

    ul
    {
    list-style:none;
    line-height: 22px;
    padding-left: 0px;
    margin-top:2px;
    margin-left:0px;

    a
    {
        color:white;
        text-decoration: none;
    }

    a:hover
    {
        text-decoration: underline;
    }
    }
    li
    {
        font-size: 12px;
    }
}


.footerHeader
{
    font-weight: bold;


}